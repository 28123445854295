import Setting from "../Setting";
import _ from "lodash";
import {CurveType} from "../../constans/feedingTypes";

/**
 * Klasa Curve reprezentujaca krzywa żywienia
 * @extends Setting
 */
class Curve extends Setting {

    constructor(setting) {
        if (!setting.SetData) throw new Error("SetData missing");
        if (!_.isFinite(setting.SetData.Index)) throw new Error("Curve Index is not a number");
        if (!setting.SetData.Type) throw new Error("Curve Type is not specified");
        if (!Object.values(CurveType).includes(setting.SetData.Type)) throw new Error(`Curve Type is not one of [${Object.values(CurveType).join(", ")}]`);
        if (!setting.SetData.Name) throw new Error("Curve Name is not specified");
        if (!setting.SetData.Days || !setting.SetData.Days.length) throw new Error("Curve Days must include at least single day");
        setting.SetData.Days.forEach(day => {
            if (!_.isFinite(day.DailyPlan)) throw new Error("Curve Days[x].DailyPlan must be a number");
            if (!_.isFinite(day.ForageAmount)) throw new Error("Curve Days[x].ForageAmount must be a number");
            if (!_.isFinite(day.ForageType)) throw new Error("Curve Days[x].ForageType must be a number");
        });
        setting.SetData.Stages && setting.SetData.Stages.forEach(day => {
            if (!_.isFinite(day.StartDay)) throw new Error("Curve Stages[x].StartDay must be a number");
            if (!day.Name) throw new Error("Curve Stages[x].Name must be present");
        });
        super(setting);
    }

    convertToBackend(updateAPI = true) {
        return {
            days: _.cloneDeep(this.SetData.Days).map(day => {
                return {
                    amount: day.ForageAmount,
                    schedule: day.DailyPlan + 1,
                    forage: day.ForageType + 1
                }
            }),
            number: this.SetData.Index + 1,
            insemination: this.SetData.InseminationJumpTo || 0,
            parturition: 0, // this.SetData.ParturitionJumpTo || 0,
            id: this.SetID,
            API: updateAPI ? this.prepareBeanToSave() : undefined // jezeli bedzie przekazane to zapisze tez do settingsow
        };
    }

    getIndex() {
        return this.SetData.Index;
    }
}

export default Curve;