import Setting from "../Setting";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {SettingTypes} from "../../constans/settingTypes";
import {timeFormatter} from "../../utils/DateTimeUtils";

/**
 * Klasa Schedule reprezentuja tryby pracy dla harmonogramów systemowych
 * @extends Setting
 */
class SystemScheduleWorktype extends Setting {

    /**
     * Tworzy nowy obiekt na podstawie przekazanych wartości
     * @param Name {string}         Nazwa trybu pracy
     * @param WorkTime {number}     Czas pracy w ms
     * @param StopTime {number}     Czas przerwy w ms
     * @param Index {number}        Indeks trybu pracy
     * @return {SystemScheduleWorktype}
     */
    static createSystemScheduleWorktype(Name, WorkTime, StopTime, Index) {
        let state = store.getState();
        const {user: {user: {ClientID}}, location: {farm}} = state;
        return new SystemScheduleWorktype({
            SetID: myID(),
            DtaModTime: new Date().getTime(),
            ClientID,
            FarmID: farm,
            SetType: SettingTypes.SYSTEM_SCHEDULE_WORKTYPE,
            SetData: {
                Name,
                WorkTime,
                StopTime,
                Index
            }
        });
    }

    getSelectName() {
        return `${this.SetData.Index} - ${this.SetData.Name} - ${timeFormatter(this.SetData.WorkTime)} / ${timeFormatter(this.SetData.StopTime)}`
    }

    getBackendObject() {
        return {
            nr: this.SetData.Index + 1,
            mode: {
                tOn: this.SetData.WorkTime,
                tOff: this.SetData.StopTime
            }
        }
    }

}

export default SystemScheduleWorktype;