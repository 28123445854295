import Setting from "../Setting";

/**
 * Klasa Economy reprezentuje pasze przypisane do miejsc
 * @extends Setting
 */
class ClimateCurve extends Setting {

    constructor(setting) {
        if (!setting.SetData) throw new Error("SetData missing");
        super(setting);
    }

    getLength() {
        const {SetData: {Stages}} = this;
        return Stages.reduce((a, b) => a + b.Days, 0);
    }

    getBackendStages() {
        return this.SetData.Stages.map(stage => ({
            days: stage.Days,
            requestedTemperature: stage.ExpectedTemperature,
            minimumVentilation: stage.Ventilation[0],
            maximumVentilation: stage.Ventilation[1]
        }));
    }

    getMinAndMax() {
        let min = 0;
        let max = 0;
        for (let i = 1; i >= 0; i--) {
            let stage = this.SetData.Stages[i];
            if (i === 0) {
                min -= stage.Days;
            } else {
                min = -stage.Days;
            }
        }
        for (let i = 3; i < this.SetData.Stages.length; i++) {
            let stage = this.SetData.Stages[i];
            max += stage.Days;
        }
        let lastStage = this.SetData.Stages[7];
        max += lastStage.Days;
        return {min, max};
    }
}

export default ClimateCurve;