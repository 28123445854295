import Setting from "../Setting";
import {isEqual} from "lodash";

/**
 * Klasa ClimateCurveSK3 reprezentuje etapy krzywej klimatu sk3
 * @extends Setting
 */
class ClimateCurveSK3 extends Setting {

    constructor(setting) {
        if (!setting.SetData) throw new Error("SetData missing");
        super(setting);
    }

    getLength() {
        const {SetData: {Stages}} = this;
        return Stages.reduce((a, b) => a + b.Days, 0);
    }

    getBackendStages() {
        return this.SetData.Stages.map(stage => ({
            days: stage.Days,
            requestedTemperature: stage.RequestedTemperature,
            minimumVentilation: stage.MinimumVentilation,
            floor: stage.Floor
        }));
    }

    equalCheck(backendStages = []) {
        const objectStages = this.getBackendStages();
        return isEqual(objectStages, backendStages);
    }

    setBackendStages(backendStages=[]){
        this.SetData.Stages = backendStages.map(stage=>({
            Days: stage.days,
            RequestedTemperature: stage.requestedTemperature,
            MinimumVentilation: stage.minimumVentilation,
            Floor: stage.floor
        }))
    }
}

export default ClimateCurveSK3;
