import Setting from "../Setting";
import store from "../../store/store";
import {myID} from "../../libs/generateID";
import {SettingTypes} from "../../constans/settingTypes";

/**
 * Klasa Schedule reprezentuja tryby pracy dla harmonogramów systemowych
 * @extends Setting
 */
class SystemSchedule extends Setting {

    /**
     * Tworzy harmonogram systemowy
     * @param Name {string}         Nazwa harmonogramu
     * @param Intervals {Array<{StartTime: number, StopTime: number, WorkType: string}>}     Lista interwałów
     * @param Index {number}        Indeks harmonogramu
     * @return {SystemSchedule}
     */
    static createSystemSchedule(Name, Intervals, Index) {
        let state = store.getState();
        const {user: {user: {ClientID}}, location: {farm}} = state;
        return new SystemSchedule({
            SetID: myID(),
            DtaModTime: new Date().getTime(),
            ClientID,
            FarmID: farm,
            SetType: SettingTypes.SYSTEM_SCHEDULE,
            SetData: {
                Name,
                Index,
                Intervals
            }
        });
    }

    getBackendObject() {
        const state = store.getState();
        const {settings: {scheduleWorktypes}} = state;
        return {
            nr: this.SetData.Index + 1,
            intervals: this.SetData.Intervals.map(interval => {
                let worktype = scheduleWorktypes.find(item => item.SetID === interval.WorkType);
                if (!worktype) throw new Error("Nie odnaleziono trybu pracy harmonogramu");
                return {
                    st: interval.StartTime,
                    sp: interval.StopTime,
                    t: worktype.SetData.Index
                }
            })
        }
    }

}

export default SystemSchedule;