import Setting from "../Setting";
import _ from "lodash";
import ForageIngredient from "../ForageIngredient";


/**
 * Klasa Forage reprezentuja pasze
 * @extends Setting
 */
class Forage extends Setting {

    constructor(setting) {
        if (!setting.SetData) throw new Error("SetData missing");
        if (!_.isFinite(setting.SetData.Efficiency)) throw new Error("Forage Efficiency must be a number");
        if (!_.isFinite(setting.SetData.Index)) throw new Error("Forage Index must be a number");
        if (!setting.SetData.Name) throw new Error("Forage Name must be specified");
        super(setting);
    }

    convertToBackend() {
        return {
            nr: this.SetData.Index + 1,
            feedOverTime: this.SetData.Efficiency,
            id: this.SetID
        }
    }

    getRecipe() {
        //nie ma receptury zwroc pusty obiekt
        if (!_.isArray(this.SetData.Recipe)) {
            return [];
        }
        return this.SetData.Recipe;
    }

    getRecipeCosts() {
        try {
            return this.getRecipe().reduce((prev, component) => ((component.Percentage * component.Cost) / 100) + prev, 0);
        } catch (e) {
            return 0;
        }
    }

    getCost() {
        try {
            if (_.isFinite(this.SetData.Cost)) {
                return this.SetData.Cost;
            }
            return this.getRecipeCosts() || 0;
        } catch (e) {
            return 0;
        }
    }

    addIngredient(ingredientBean, percentage, cost = 0) {
        if (ingredientBean instanceof ForageIngredient) {
            const recipe = _.cloneDeep(this.getRecipe());
            const ingredient = {
                Cost: cost,
                Percentage: percentage,
                IngredientID: ingredientBean.WordID,
                Name: ingredientBean.WData.Name
            };
            recipe.push(ingredient);
            recipe.sort((o1, o2) => o1.Percentage - o2.Percentage);
            this.SetData.Recipe = recipe;
        }
    }

    removeIngredient({SetID} = {}) {
        this.SetData.Recipe = _.cloneDeep(this.getRecipe()).filter(o => o.SetData.IngredientID !== SetID);
    }

    /**
     * Pobranie ID pierwotnej paszy z której dana pasza się wywodzi - jeśli null to pasza jest pierwotna i zwracamy jej wlasne ID
     * @return {*|null}
     */
    getOriginalForageID() {
        return this.SetData.PrimeForageID || this.SetID;
    }

    /**
     * Ustawienie forage ID pierwszej paszy z której ta się wywodzi aby potem może użyć to w wykresach
     * @param SetID
     */
    setOriginalForageID(SetID = null) {
        this.SetData.PrimeForageID = SetID;
    }
}

export default Forage;