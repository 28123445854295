import Setting from "../Setting";
import _ from "lodash";

/**
 * Klasa Schedule reprezentuja dzienne plany zywienia
 * @extends Setting
 */
class Schedule extends Setting {

    constructor(setting) {
        if (!setting.SetData) throw new Error("SetData missing");
        if (!_.isFinite(setting.SetData.Index)) throw new Error("Schedule Index must be a number");
        if (!setting.SetData.Name) throw new Error("Schedule Name must be specified");
        if (!setting.SetData.Doses || !setting.SetData.Doses.length) throw new Error("Schedule Doses must include at least single dose");
        setting.SetData.Doses.forEach(dose => {
            if (!_.isFinite(dose.Percent)) throw new Error("Schedule Doses[x].Percent must be a number");
            if (!_.isFinite(dose.Start)) throw new Error("Schedule Doses[x].Start must be a number");
            if (!_.isFinite(dose.Stop)) throw new Error("Schedule Doses[x].Stop must be a number");
        });
        super(setting);
    }

    convertToBackend(updateAPI = true) {
        return {
            doses: _.cloneDeep(this.SetData.Doses).map(dose => {
                return {
                    percent: dose.Percent,
                    start: dose.Start,
                    stop: dose.Stop
                }
            }),
            number: this.SetData.Index + 1,
            id: this.SetID,
            API: updateAPI ? this.prepareBeanToSave() : undefined
        };
    }
}

export default Schedule;