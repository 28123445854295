import Setting from "../Setting";

/**
 * Klasa General reprezentuje glowne ustawienia aplikacji
 * @extends Setting
 */
class General extends Setting {

    constructor(setting) {
        //todo: zobaczyc z czego uzwamy i errory wysypac na tym co potrzebne
        if (!setting.SetData) throw new Error("SetData missing");
        super(setting);
    }
}

export default General;