import Setting from "../Setting";

/**
 * Klasa FarmMap reprezentujaca mape fermy
 * @extends Setting
 */
class FarmMap extends Setting {

    constructor(setting) {
        //todo: pewnie bean sie zmieni jak bedzie zapisywanie na s3
        if (!setting.SetData) throw new Error("SetData missing");
        if (!setting.SetData.Levels) throw new Error("FarmMap Buildings must include an KeyLevel-ValSVGString");
        super(setting);
    }
}

export default FarmMap;