import Setting from "../Setting";

/**
 * Klasa Plans reprezentuje plany w wynikach
 * @extends Setting
 */
class Plans extends Setting {

    constructor(setting) {
        //todo: zobaczyc z czego uzwamy i errory wysypac na tym co potrzebne
        if (!setting.SetData) throw new Error("SetData missing");
        super(setting);
    }

    setPlan(year, data) {
        let plan = this.SetData.Plans.find(item => item.Year === year);
        if (plan) {
            plan.Data = data;
        } else {
            this.SetData.Plans.push({
                Year: year,
                Data: data
            })
        }
        this.setDtaModTime();
    }
}

export default Plans;