import Setting from "../Setting";

/**
 * Klasa Economy reprezentuje pasze przypisane do miejsc
 * @extends Setting
 */
class DeviceSetting extends Setting {

    constructor(setting) {
        //todo: zobaczyc z czego uzwamy i errory wysypac na tym co potrzebne
        if (!setting.SetData) throw new Error("SetData missing");
        if (!setting.SetData.Plcmnts) throw new Error("Brak SetData Plcmnts");
        super(setting);
    }
}

export default DeviceSetting;